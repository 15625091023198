import React from "react"
import Markdown from "react-markdown";

import {graphql, useStaticQuery} from 'gatsby'

import Layout from '../containers/layout'

import Container from '../components/container'
import BlogPostPreviewList from '../components/blog-post-preview-list'
import Breadcrumb from '../components/breadcrumb'
import DynamicZoneComponent from '../components/dynamic-zone-component'
import MetaSchemaSEO from '../components/seo';

//import * as styles from './about.module.css'

export const query = graphql`
  query aboutQuery {
    strapi { 
      siteSetting {
        about_us_page
        }
      pages( where: {slug:"about"}, limit:1 ) {
        id
        title
        title_short
        excerpt
        published_at
        created_at
        updated_at
        page_type{
          name
        }
        slug
        main_image {
          url
          caption
          alternativeText
          imageFile{
            childImageSharp{
              gatsbyImageData(
                width: 1200,
                aspectRatio: 1.4,
                transformOptions: {cropFocus:ENTROPY})
            }
          }
        }
        authors {
          id
          name
          slug
          entity_type {
            slug_prefix
            name
          }
        }
        body_introduction
        body {
          __typename
          ... on STRAPI_ComponentContentBikeMap {
            id
          }
          ... on STRAPI_ComponentContentListTopN {
            id
            introduction
            section_image {
              url
              caption
              alternativeText
              imageFile{
                childImageSharp{
                  gatsbyImageData(
                    width: 755
                  )
                }
              }
            }
            description
            title
            offers {
              id
              title
              offer
              external_url
              page{
                slug
              }
            }
            is_ordered
            page {
              slug
              title
              page_type {
                name
                slug_prefix
              }
              parent {
                slug
                page_type {
                  name
                  slug_prefix
                }
              }
            }
          }
          ... on STRAPI_ComponentContentEmbeddedHtml {
            id
            raw_html
          }
          ... on STRAPI_ComponentMediaImage {
            id
          }
          ... on STRAPI_ComponentContentQuestionAndAnswer {
            id
            Question
            answer
          }
        }
        body_conclusion
        authorities {
          id
          name
          slug
          entity_type {
            slug_prefix
            name
          }
        }
        primary_topic
        parent: parent {
          title
          slug
        }
        page_type {
          name
          slug_prefix
        }
        children {
          id
          canonical_url
          slug
          page_type {
            name
            slug_prefix
          }
          title
          title_short
          primary_topic
          excerpt
          page_value
          updated_at
          parent {
            title
            title_short
            slug
          }
          main_image {
            url
            caption
            alternativeText
            imageFile{
              childImageSharp{
                gatsbyImageData(
                  width: 300,
                  aspectRatio: 1,
                  transformOptions: {cropFocus:ENTROPY})
              }
            }
          }
        }
      }}
    }
  `;
  
  

// markup
const AboutPage = () => {

  const data = useStaticQuery(query);
  const pages = data.strapi.pages
  const article = pages[0] || false

  return (
    <Layout>
      <MetaSchemaSEO
        title = {article.title}
        article = {article}
      />
      <div>
          <Container>
              <Breadcrumb thisPage={article}></Breadcrumb>
              <h1>{article.title}</h1>
          </Container>
      </div>
      {!article && data.strapi.siteSetting.about_us_page && 
        <Container>
          <Markdown children={data.strapi.siteSetting.about_us_page} skipHtml={true} />
        </Container>
      }
      { article &&  
        <>

          <div><Container>
              <Markdown children={article.body_introduction} skipHtml={true} />
          
          {
          // add children posts if any
          article.children && article.children.length > 0 && (
              <BlogPostPreviewList
                  nodes={article.children}
                  max_n="100"
                  page_type={article.page_type}
              />
          )}
          </Container></div>
          {
              // display body components
              article.body && article.body.map((component, index) => {
                  return (component && 
                      <>
                          <DynamicZoneComponent component={component}></DynamicZoneComponent>
                      </>
                  )
              }
              )
          }
          <div><Container>
          <Markdown children={article.body_conclusion} skipHtml={true} />
          </Container></div>
        </>
      }
    </Layout>
  )
}

export default AboutPage
